import React from "react";
import PropTypes from "prop-types";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Trash from "components/cc/Trash";
import { ModalRoute } from "seed/helpers";
import { getHelpMessage, getHelpComponent } from "components/utils/constants";
import { BrowserRouter, Link } from "react-router-dom";
import DocumentList from "components/cc/DocumentList";


const MainView = ({
  partners,
  filters,
  pageNum,
  pageSize,
  onClickPage,
  onChangeFilter,
  setPageNum,
  onClickUpdateDocuments,
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <BrowserRouter basename="/cc_home">

      <div class="card mb-3 mb-lg-5 p-3 h-100 w-100">

        <div class="card-header justify-content-between">
          <h3 class="card-header-title m-2">Opiniones</h3>
          <div className="d-flex align-items-center">
            <Link to="/trash" className="btn btn-sm btn-soft-secondary m-2">
              <i class="fas fa-trash-restore mr-2" style={{ color: "#aaa" }}></i>Papelera
            </Link>
            <a className="btn btn-sm btn-soft-primary m-2" onClick={onClickUpdateDocuments}>
              <i class="tio-update mr-2" style={{ color: "#2080e5" }}></i>Sincronizar opiniones
            </a>
            <span className="d-inline-block mr-5">
              <label class="toggle-switch d-flex align-items-center" for="ept">
                <input type="checkbox" class="toggle-switch-input" id="ept" name="ept"
                  onChange={(event) => { onChangeFilter(event, "ept") }} checked={filters["ept"]} />
                <span class="toggle-switch-label">
                  <span class="toggle-switch-indicator"></span>
                </span>
                <span class="toggle-switch-content mr-2">
                  <span class="text-secondary">Mostrar EPT</span>
                </span>

              </label>
            </span>
            <Link to="/create" className="btn btn-sm btn-primary m-2">
              <i class="tio-add mr-2" style={{ color: "white" }}></i>Registrar opinión
            </Link>
          </div>
        </div>

        <div class="card-body">
          <div class="card w-100 h-100">

            {/* Filters */}
            <div class="card-header">
              <div class="row justify-content-between align-items-center flex-grow-1">

                <div class="col-sm-12">
                  <form>
                    <div class="row">

                      {/* Document date year filter */}
                      <div class="col-md-2">
                        <div class="d-flex align-items-center mr-2">
                          <span class="d-flex text-secondary mr-2">
                            Mes doc {getHelpComponent(getHelpMessage("document_date_month"))}
                          </span>
                          <div class="input-group input-group-merge input-group-flush mr-3">
                            <select
                              name="month"
                              value={filters["month"]}
                              class="form-control js-select2-custom js-datatable-filter custom-select"
                              onChange={(event) => { onChangeFilter(event, "month") }}
                            >
                              <option value="all" >Todos</option>
                              <option value="none">Sin fecha</option>
                              <option value="1">Enero</option>
                              <option value="2">Febrero</option>
                              <option value="3">Marzo</option>
                              <option value="4">Abril</option>
                              <option value="5">Mayo</option>
                              <option value="6">Junio</option>
                              <option value="7">Julio</option>
                              <option value="8">Agosto</option>
                              <option value="9">Septiembre</option>
                              <option value="10">Octubre</option>
                              <option value="11">Noviembre</option>
                              <option value="12">Diciembre</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* Document date year filter */}
                      <div class="col-md-2">
                        <div class="d-flex align-items-center mr-2">
                          <span class="d-flex text-secondary mr-2">
                            Año doc {getHelpComponent(getHelpMessage("document_date_year"))}
                          </span>
                          <div class="input-group input-group-merge input-group-flush mr-3">
                            <select
                              name="year"
                              value={filters["year"]}
                              class="form-control js-select2-custom js-datatable-filter custom-select"
                              onChange={(event) => { onChangeFilter(event, "year") }}
                            >
                              <option value="all" >Todos</option>
                              {
                                Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                  <option key={year} value={year}>{year}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* Status filter */}
                      <div class="col-md-2">
                        <div class="d-flex align-items-center ml-2 mr-2">
                          <span class="text-secondary mr-2">Estado</span>
                          <div class="input-group input-group-merge input-group-flush mr-3">
                            <select
                              name="status"
                              value={filters["status"]}
                              class="form-control js-select2-custom js-datatable-filter custom-select"
                              onChange={(event) => { onChangeFilter(event, "status") }}
                            >
                              <option value="all" >Todos</option>
                              <option value="SENT">Por asignar</option>
                              <option value="ASSIGNED">▶️ Propuesto a asignar</option>
                              <option value="IN_REVIEW">⚙️ En revisión</option>
                              <option value="REVIEWED">✅ Revisado</option>
                              <option value="WITH_COMMENTS">⚠️ Revisado con comentarios</option>
                              <option value="NEEDS_COMMITTEE">⚠️ Revisado con comité</option>
                              <option value="REVIEWED_WITH_COMMENTS">📃 Corregido con comentarios</option>
                              <option value="REVIEWD_WITH_COMMITTEE">📃 Corregido con comité</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* Partners filter */}
                      <div class="col-md-2">
                        <div class="d-flex align-items-center mr-2">
                          <span class="text-secondary mr-2">Socio revisor</span>
                          <div class="input-group input-group-merge input-group-flush mr-3">
                            <select
                              name="partner"
                              value={filters["partner"]}
                              class="form-control js-select2-custom js-datatable-filter custom-select"
                              onChange={(event) => { onChangeFilter(event, "partner") }}
                            >
                              <option value="all" >Todos</option>
                              {
                                partners.map((partner) => (
                                  <option key={`partner_${partner.id}`} value={partner.id}>
                                    {partner.firstName + " " + partner.lastName}
                                  </option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* Delayed filter */}
                      <div class="col-md-2">
                        <div class="d-flex align-items-center mr-2">
                          <div class="input-group input-group-merge input-group-flush mr-3">
                            <label class="toggle-switch d-flex align-items-center mb-3" for="delayed">
                              <input type="checkbox" class="toggle-switch-input" id="delayed" name="delayed"
                                onChange={(event) => { onChangeFilter(event, "delayed") }} checked={filters["delayed"]} />
                              <span class="toggle-switch-label">
                                <span class="toggle-switch-indicator"></span>
                              </span>
                              <span class="toggle-switch-content mr-2">
                                <span class="text-secondary">Solo retrasadas</span>
                              </span>

                            </label>
                          </div>
                        </div>

                      </div>

                      {/* Clients filter */}
                      <div class="col-md-2">
                        <div class="d-flex align-items-center mr-2">
                          <span class="text-secondary mr-2">Cliente</span>
                          <div class="input-group input-group-merge input-group-flush mr-3">
                            <input
                              name="client"
                              value={filters["client"]}
                              class="form-control"
                              onChange={(event) => { onChangeFilter(event, "client") }}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* List */}
            <DocumentList
              filters={filters}
              pageNum={pageNum}
              pageSize={pageSize}
              onClickPage={onClickPage}
              onChangeFilter={onChangeFilter}
              setPageNum={setPageNum}
            />

          </div>
        </div>
      </div>

      <ModalRoute path="/trash" width="1200" height="800" component={Trash} />
    </BrowserRouter>
  </LocalizationProvider>
);

MainView.propTypes = {
  months: PropTypes.array,
  clients: PropTypes.array,
  partners: PropTypes.array,
  filters: PropTypes.string,
  deadline: PropTypes.object,
  syncDate: PropTypes.object,
  pageNum: PropTypes.number,
  pageSize: PropTypes.number,
  onClickPage: PropTypes.func,
  onChangeFilter: PropTypes.func,
  onChangeSyncDate: PropTypes.func,
  onClickUpdateDocuments: PropTypes.func,
};

export default MainView;